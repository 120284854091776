@import '../../assets/styles/colors.scss';

.reportContainer{
    .accordion {
        .header{
            padding: 10px 0;
            height:auto;
            color: #262626;
            font-size: 17px;
            .toggleIcon{
                position: absolute;
                top: 8px;
                right: 24px;
            }
        }
        
        .borderLine {
            border-bottom: 2px solid #979797;
        }
    }
    .head{
        background: #262626;
        color: white;
        padding: 10px;
        font-size: 13px;
    }
    .nodata{
        background: #fff;
        color: #000;
        padding: 10px;
        border: 1px solid #000;
        span {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
        }
    }
    .table-responsive {
        margin-left: 15px;
    }
    thead {
        background-color: $header-dark;
        color: $white;
        text-align: center;
        text-transform: uppercase;
    }
}