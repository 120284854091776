@import '../variables';
@import '../colors';
// -----------------------------------------------------------------------------
// This file contains all styles related to the checkbox component.
// -----------------------------------------------------------------------------

.radio {
    -webkit-appearance: none;
	display: inline-block;
    position: relative;
	border-radius: 50px;
    outline: none;
    width: 13px;
    height: 13px;
    margin-top: 2px;
    &:checked:after {
        content: ' ';
        width: 5px;
        height: 5px;
        font-size: 10px;
        border-radius: 50px;
        position: absolute;
        top: 3px;
        left: 3px;
        font-weight: bold;
    }
    &.light {
        background-color: $white;
        border: 1px solid $list;
        &:checked:after {
            background: $mid-dark;
        }
    }
    &.dark {
        background-color: $black;
        border: 1px solid $black;
        &:checked:after {
            background: $white;
        }
    }
}