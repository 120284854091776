// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @param {Bool} $self [false] - Whether or not to include current selector
@mixin on-event($self: false) {
    @if $self {
        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    } @else {
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
}

/// Make a context based selector a little more friendly
/// @param {String} $context
@mixin when-inside($context) {
    #{$context} & {
        @content;
    }
}

/// Apply gradient to the element
/// @param {Color} $direction
/// @param {Color} $color-stops
@mixin linear-gradient($direction, $color-stops...) {
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}

/// Replace the default scroll bar
/// @param {Color} $scrollerColor
/// @param {Color} $backgroundColor
@mixin customScroller($scrollerColor, $backgroundColor: $mild-dark, $boxShadow: rgba(0,0,0,.3)) {    
    &::-webkit-scrollbar{width:10px; background: $backgroundColor}
    &::-webkit-scrollbar-thumb{background: $scrollerColor; border-radius:5px}
    &::-webkit-scrollbar-track{box-shadow:inset 0 0 6px $boxShadow; background:$backgroundColor}
}

/// generic transform
/// @param {Transform} $transforms
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

/// Apply box-shadow to the element
/// @param {Length} $horLength
/// @param {Length} $verLength
/// @param {Length} $blur
/// @param {Length} $radius
/// @param {Color} $color
/// @param {Bool} $inset
@mixin box-shadow($horLength, $verLength, $blur, $radius, $color, $inset: false) {  
    @if $inset {
        -webkit-box-shadow:inset $horLength $verLength $blur $radius $color;
        -moz-box-shadow:inset $horLength $verLength $blur $radius $color;
        box-shadow:inset $horLength $verLength $blur $radius $color;
    } @else {
        -webkit-box-shadow: $horLength $verLength $blur $radius $color;
        -moz-box-shadow: $horLength $verLength $blur $radius $color;
        box-shadow: $horLength $verLength $blur $radius $color;
    }
}

/// Apply calculated Height between 2 units
/// @param {Property} $property
/// @param {Length} $a
/// @param {Length} $b
@mixin calc($property, $a, $b) {
    #{$property}: -webkit-calc(#{$a} - #{$b});
    #{$property}: expression(#{$a} - #{$b});
    #{$property}: -moz-calc(#{$a} - #{$b});
    #{$property}: -o-calc(#{$a} - #{$b});
    #{$property}: calc(#{$a} - #{$b});
}

/// Apply text-shadow for the text in the element
/// @param {Length} $x
/// @param {Length} $y
/// @param {Length} $blur
/// @param {Color} $color
@mixin text-shadow($x: 1px, $y: 1px, $blur: 3px, $color: rgba(0, 0, 0, 0.4)) {
    text-shadow: $x $y $blur $color
}

/// Apply animation effect for the element
/// @param {String} $str
/// example: @include animation('slide-down 5s 3');
@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

/// Add ... for text overflow
@mixin text-overflow() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
