@import './variables';
@import './colors';
.notification-container {
    top: inherit;
    bottom: 20px;
}
.notification {
    font-family: $Helvetica;
    box-shadow: 0 0 12px $mid-dark;
    .title{
        font-size: 1.2em;
    }
}

// Date picker
.react-datepicker-wrapper{
    .react-datepicker__input-container input.form-control{
        padding: 14px 10px 14px 40px !important;
        height: 40px;
        background: url('../images/calendar.png') no-repeat 10px 10px;
        background-size: 10%;
    }
}
.react-datepicker__tab-loop{
    .react-datepicker-popper{
        margin:0;
        .react-datepicker{
            border-radius: 0;
            border: 1px solid $border-dark;
        }
    }
    .react-datepicker__month-container{
        padding: 20px;
        .react-datepicker__header{
            padding: 0;
            background: none;
            border: 0;
            .react-datepicker__current-month{
                font-size: 18px;
                font-family: $TradeGothic;
                text-transform: uppercase;
                color: $dark-color;
            }
            .react-datepicker__day-names{
                .react-datepicker__day-name{
                    font-family: $Helvetica;
                    font-size: 9px;
                    color: $border-dark;
                    text-transform: uppercase;
                    // line-height: 19px;
                    margin: 1px;
                }
            }
			.react-datepicker__header__dropdown{
				.react-datepicker__month-read-view--down-arrow{
					// top: 4px;
                    margin-right: 25px;
                    background: url('../images/drop-down.png') no-repeat;
                    width: 15px;
                    height: 15px;
                    border: none;
                    transform:rotate(360deg);
				}
                .react-datepicker__month-read-view--selected-month{
                    margin-right: 25px;
				}
                .react-datepicker__year-read-view--down-arrow{
                    background: url('../images/drop-down.png') no-repeat;
                    width: 15px;
                    height: 15px;
                    border: none;
                    transform:rotate(360deg);
				}
			}
        }
        .react-datepicker__month{
            margin: 0 0.4rem;
            .react-datepicker__day{
                border-radius: 0;
                background: $scrollBar-light;
                font-family: $Helvetica;
                font-size: 12px;
                color: $dark-color;
                margin: 1px;
                &.react-datepicker__day--selected{
                    color: $white;
                    background: $blue;
                }
                &:hover{
                    color: $white;
                    background: lighten($blue, 20%);
                }
                &.react-datepicker__day--disabled{
                    opacity: 0.5;
                    &:hover{
                        color: $dark-color;
                        background: $scrollBar-light;
                    }
                }
            }
        }
    }
    
    .react-datepicker__navigation{
        width: 20px;
        height: 20px;
        outline: none;
        border: 0;
        opacity: 0.8;
        top: 120px;
        &.react-datepicker__navigation--previous{
            background: url('../images/left-arrow.png') no-repeat;
            left: 2px;
        }
        &.react-datepicker__navigation--next{
            background: url('../images/right-arrow.png') no-repeat;
            right: 2px;
        }
        &:hover{
            opacity: 1;
        }
    }
    .react-datepicker__triangle{
        display: none;
    }
    .react-datepicker__navigation-icon {
        display: none;
    }
}