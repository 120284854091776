.radioSelect{
    margin:0;
    padding:0;
    li{
        list-style: none;
        margin:8px 0;
        label{
            margin: 0 0 0 5px;
            text-transform: uppercase;
        }
    }
}