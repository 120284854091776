.planGrid{
    width: 100%;
    height: calc(100% - 60px);
	position: relative;
	.bi-arrow-repeat{
		position: absolute;
		width: 32px;
		height: 32px;
		top: 0;
		right: 0;
		cursor: pointer;
		z-index: 1;
		font-size: 16px;
		line-height: 32px;
		text-align: center;
	}
}
.custom-date-filter {
    position: relative;
  }

.ag-react-container{
  height:100%;
}
.ag-header-cell-label{
  color: #000;
}
.highlightClass{
  background-color:rgba(255,255,0,1);
  color:#000;
}
.ag-theme-balham .ag-header-cell::after{
  height: inherit;
  margin-top: 0;
}

.cellhighlightClass{
  background-color:orange;
}
.rowhighlightClass{
  background-color: #dc3434b3 !important;
}
