@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
.header{
    .headerTop{
        height: 38px;
        background: $black;
        padding: 0 15px;
        .logo {
            background-position: center center;
            width: 32px;
            height: 12px;
            background: url('../../../assets/images/logo.png') no-repeat;
            margin-top: 12.5px;
            background-size: cover;
            text-indent: -99999px;
        }
        h3{
            color: $white;
            font-size: 15px;
            font-family: $TradeGothic;
            text-transform: uppercase;
            line-height: 38px;
            margin: 0 0 0 10px;
        }
        .user{
            font-family: $Helvetica;
            font-size: 12px;
            color: $white;
            line-height: 38px;
            text-transform: uppercase;
        }
    }
    .menuBar {
        background: $mid-dark;
        height: 54px;
        line-height: 54px;
        padding: 0 0 0 27px;
        text-align: center;
        li{
            list-style: none;
            display: inline-block;
            font-family: $TradeGothic;
            font-size: 24px;
            margin: 0;
            text-transform: uppercase;
            padding: 0 30px;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                height: 30px;
                width: 2px;
                background: #2c2c2c;
                right: 0px;
                top: 12px;
            }
            &:last-child{
                &::after{
                    content: none;
                }
            }
            a {
                opacity: 0.5;
                color: $white;
                text-decoration: none;
                &.active, &:hover{
                    opacity: 1;
                    text-decoration: none;
                }
            }
        }
    }
}
