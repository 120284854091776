.planContainer{
    flex-direction:column;
    display:flex;
    height: 100%;
    .topSection{
        margin-bottom: 20px;
        .btn{
            margin-right: 10px;
        }
    }
    .dropdown-container{
        float:right;
        width:200px;
    }
}