@import '../variables';
@import '../colors';

.form-group{
    label{
        font-family: $TradeGothic;
        color: $dark-color;
        font-size: 15px;
        font-weight: 400;
    }
    input, textarea{
        font-size: 12px;
    }
}
.buttonsSection{
    text-align: right;
    padding-top: 10px;
}
