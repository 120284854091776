@import '../../../assets/styles/variables';
@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';
.accordion{
    height: 100%;
    .header{
        font-family: $TradeGothic;
        font-size: 15px;
        color: $mid-dark;
        text-transform: uppercase;
        height: 35px;
        line-height: 35px;
        position: relative;
        &.notValid{
            color: $dark-red;
            .validationMessage{
                text-transform: lowercase;
                color: $dark-red;
                padding-left: 4px;
                position: relative;
                top: 0;
            }
        }
        .mandatory{
            font-size: 16px;
            color: $dark-red;
            margin-left: 2px;
        }
        .toggleIcon {
            width: 15px;
            height: 35px;
            position: relative;
            cursor: pointer;
            color: $mid-dark;
            &::after{
                content: '+';
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                font-size: 1.3em;
                line-height: 35px;
            }
            &.open{
                &::after{ content: '-'; font-size: 2.5em; }
            }
        }
    }
    .content{
        height: 0;
        transition: opacity 500ms ease-out;
        opacity: 0.0;
        font-size: $default-font-size;
        color: $list;
        font-family: $Helvetica;
        height: 0;
        max-height: calc(100% - 35px);
        overflow: hidden;
        overflow-y: auto;
        @include customScroller($scrollBar-light, $white, $white);
        &.open {
            transition: opacity 500ms ease-in;
            opacity: 1;
            height: auto;
            &.overflowInherit{
                overflow: inherit;
            }
        }
    }
    .borderLine{        
        border-bottom: 1px solid $border;
    }
}


