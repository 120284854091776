$black: #000;
$white: #fff;
$blue: #3f96c2;
$blue1: #149BE0;
$hoverBlue: #e4f3fa;
$dark-color: #222;
$mid-dark: #333;
$mild-dark: #999;
$other-dark1: #676767;
$header-dark: #545454;

$border: #f2f2f2;
$border-dark: #ccc;

$red: #d43f21;
$dark-red:#ff0000;

$list: #666;

$scrollBar-light: #e5e5e5;

$listHover: #e4f2f9;

$background1: #f1f1f1;
