@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

@mixin spinnerBar($i) {
    -webkit-transform: rotate( ($i * 30.17deg) ) translate(0, -130%);
    -webkit-animation: fade 1s linear infinite;
    -webkit-animation-delay: 0s - ((12-$i) / 12);
}

@keyframes fade {
    0%  { opacity: 1; }
    100% { opacity: .1; }
}
  
.loader{
    position: fixed;
    left:0;
    top:0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 9999;

    .spinner {
        position:fixed;
        @include calc(left, 50%, 25px);
        @include calc(top, 50%, 25px);
        width: 50px;
        height: 50px;
      
        div {
            position: absolute;
            top: 38%;
            left: 48%;
            width: 6%;
            height: 24%;
            background: #fff;
            opacity: 1;
            border-radius: 3px;
            box-shadow: 0 0 3px rgba(0,0,0,.1);
        
            /* Bind the animation */
            &.bar1   {	@include spinnerBar(0);	}    
            &.bar2   {	@include spinnerBar(1);	}    
            &.bar3   {	@include spinnerBar(2);	}    
            &.bar4   {	@include spinnerBar(3);	}    
            &.bar5   {	@include spinnerBar(4);	}    
            &.bar6   {	@include spinnerBar(5);	}    
            &.bar7   {	@include spinnerBar(6);	}    
            &.bar8   {	@include spinnerBar(7);	}    
            &.bar9   {	@include spinnerBar(8);	}    
            &.bar10  {	@include spinnerBar(9);	}    
            &.bar11  {	@include spinnerBar(10); }
            &.bar12  {	@include spinnerBar(11); }    
        }
    }
    .text{
        position: absolute;
        width: 100%;
        text-align: center;
        top: calc(50% + 25px);
        color: $white;
        font-family: $TradeGothic;
        text-transform: uppercase;
        font-size: 16px;
    }
}