// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

$nike-font-path: '../fonts/';
// Imported Nike Typefaces
@font-face {
  font-family: 'Futura ND';
  src: url($nike-font-path+'FuturaNDforNike365.eot'); /* IE9 Compat Modes */
  src: url($nike-font-path+'FuturaNDforNike365.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Futura ND Obl';
  src: url($nike-font-path+'FuturaNDforNike365Obl.eot'); /* IE9 Compat Modes */
  src: url($nike-font-path+'FuturaNDforNike365Obl.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Trade Gothic';
  src: url($nike-font-path+'TradeGothicforNike365.eot'); /* IE9 Compat Modes */
  src: url($nike-font-path+'TradeGothicforNike365.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Trade Gothic Bd Cnd';
  src: url($nike-font-path+'TradeGothicforNike365BdCn.eot'); /* IE9 Compat Modes */
  src: url($nike-font-path+'TradeGothicforNike365BdCn.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: TradeGothicLTStd-BdCn20;
  src: url($nike-font-path+'tradegothic-bold.woff'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'One Nike Glyphs';
  src: url($nike-font-path+'onenike-glyphs.eot');
  src: url($nike-font-path+'onenike-glyphs.ttf') format('truetype');
}
@font-face {
  font-family: 'Nike Glyphs';
  src: url($nike-font-path+'nike-glyphs.woff');
}
@font-face {
  font-family: Roboto;
  src: url($nike-font-path+'Roboto-Regular.ttf');
}
@font-face {
  font-family: Roboto;
  src: url($nike-font-path+'Roboto-Bold.ttf');
  font-weight: bold;
}