@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.multiSelectControl{
    height: 100%;
    .maxSelectionMessage{
        font-weight: bold;
        font-family: $Helvetica;
        font-size: $default-font-size;
        color: $mid-dark;
    }
    .filterText{
        width: 100%;
        border: 0;
        border-bottom: 1px solid $border;
        height: 25px;
        line-height: 25px;
        font-family: $Helvetica;
        font-size: $default-font-size;
        color: $mild-dark;
        outline: none;
        position: relative;
        padding-right: 18px;
        top: -5px;
        background: url('../../../assets/images/search.png') no-repeat;
        background-position: right center;
        background-size: 13px 13px;
    }
    .selectAllCheck{
        margin-top: 10px;
        label{
            margin: 0 0 0 5px;
            height: 13px;
            line-height: 15px;
            font-weight: bold;
            font-family: $Helvetica;
            font-size: $default-font-size;
            color: $black;
        }
    }
    .multiSelectOptions{
        @include calc(height, 100%, 48px);
        max-height: 200px;
        overflow-y: auto;
        @include customScroller($scrollBar-light, $white, $white);
        margin: 0;
        padding: 0;
        li{
            list-style: none;
            margin: 8px 0;
            label{
                margin: 0 0 0 5px;
                height: 15px;
                line-height: 15px;
                font-family: $Helvetica;
                font-size: $default-font-size;
                color: $list;
            }
            .checkbox:disabled + label{
                opacity:0.5;
            }
        }
    }
}