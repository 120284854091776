@import '../variables';
@import '../colors';
// -----------------------------------------------------------------------------
// This file contains all styles related to the checkbox component.
// -----------------------------------------------------------------------------

.checkbox {
    -webkit-appearance: none;
    width: 13px;
    height: 13px;
	display: inline-block;
    position: relative;
    outline: none;
    &:disabled{
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:checked:after {
        content: '\2714';
        font-size: 10px;
        position: absolute;
        top: -2px;
        left: 1px;
        font-weight: bold;
    }
    &.light {
        background-color: $white;
        border: 1px solid $list;
        &:checked:after {
            color: $mid-dark;
        }
    }
    &.dark {
        background-color: $black;
        border: 1px solid $black;
        &:checked:after {
            color: $white;
        }
    }
}