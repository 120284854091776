@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';
.autocomplete{
    position: relative;
    font-size: $default-font-size;
    font-family: $Helvetica;
    input {
        border-radius: 2px;
        border: solid 1px $border-dark;
        height: 30px;
        line-height: 30px;
        padding: 5px 8px;
        width: 100%;
        font-family: $Helvetica;
        color: $mild-dark;
        outline: none;
    }
    .no-suggestions {
        color: $mild-dark;
        padding: 0.5rem;
        background: $white;
        text-align: center;
        border: 1px solid $border-dark;
        position: absolute;
        width: 100%;
        left:0;
        top: 30px;
        z-index: 99;
    }
  
    .suggestions {
        position: absolute;
        border: 1px solid $border-dark;
        border-top-width: 0;
        list-style: none;
        margin: 0;
        max-height: 143px;
        overflow-y: auto;
        padding-left: 0;
        width: 100%;
        left:0;
        top: 30px;
        z-index: 99;
        @include customScroller($mild-dark, $scrollBar-light, $white);
    }
  
    .suggestions li {
        padding: 5px;
        background: $white;
    }
    
    .suggestion-active,
    .suggestions li:hover {
        background-color: $listHover;
        color: $mid-dark;
        cursor: pointer;
        font-weight: 700;
    }
    
    .suggestions li:not(:last-of-type) {
        border-bottom: 1px solid $border-dark;
    }
}
