.multiselect {
    float: right;
}
.calendarReport {
    height: 100%;
    .dropdown-container {
        float:left;
    }
	.headerSection {
		margin-bottom:15px;
	}
	.multiselect .primary{
		margin-left:10px;
	}
}
