@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
.freeTextField {
    margin: 0 0 15px 0;
    input{
        border-radius: 2px;
        border: solid 1px $border-dark;
        height: 30px;
        line-height: 30px;
        padding: 5px 8px;
        width:100%;
        font-family: $Helvetica;
        font-size: $default-font-size;
        color: $mild-dark;
        outline: none;
    }
}