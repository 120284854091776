.calenderReportgrid{
    width: 100%;
    height: calc(100% - 60px);
	position: relative;
	.bi-arrow-repeat{
		position: absolute;
		width: 32px;
		height: 32px;
		top: 0;
		right: 0;
		cursor: pointer;
		z-index: 1;
		font-size: 16px;
		line-height: 32px;
		text-align: center;
	}
}