.mainContent{
    padding:15px;
    height: calc(100% - 92px);
    position: absolute;
    width: 100%;
    top: 92px;
    left: 0;
    overflow: auto;
}
.noAccess{
    text-align: center;
    font-size: 1.5em;
    margin-top: 200px;
}