
@import '../../../assets/styles/variables';
@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.dropdown-container{
    font-family: "Open Sans", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-se;
    font-size: 12px;
    position: relative;
    outline: none;
    height: 32px;

    .dropdown_label{
        position: relative;
        outline: none;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 15px;
        &::after{
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            right: 0px;
            top: 5px;
            background: url('../../../assets/images/down_arrow.png') no-repeat;
            background-size: contain;
        }
        &.disabled{
            cursor: not-allowed;
            opacity: 0.5;
        }
        &.open{
            &::after{
                -webkit-transform: rotate(180deg);
                transform:rotate(180deg);
                -moz-transform: rotate(180deg);
            }
        }
    }
    ul{
        padding-left: 0;
        border-radius: 0 0 4px 4px;
        border: 1px solid $border-dark;
        background: $white;
        border-top: 0;
        position: absolute;
        width: 100%;
        max-height: 300px;
        top: 31px;
        left: 0;
        z-index: 100;
        overflow-y: auto;      
        @include customScroller($scrollBar-light, $white, $white);
        li{
            list-style-type: none;
            padding: 5px;
            text-align: left;
            .radio, .checkbox{
                margin: 2px 10px 0 0;
            }
            label{
                margin: 0;
            }
            &.radioList{
                padding: 5px 10px;
                cursor: pointer;
                &:hover{
                    background: $hoverBlue;
                }
                .selectRadio{
                    visibility: hidden;
                    width: 0;
                    height: 0;
                    display: none;
                }
                label{
                    display: block;
                }
            }
        }
    }
}